<template>
    <div class="home">
        <nav>
            <ol class="breadcrumb">
                <li v-for="f of fasi" :key="f.fase" class="breadcrumb-item" :class="f.class" @click="cambiaFase(f)">{{ $t(f.title) }}</li>
            </ol>
        </nav>

        <div v-if="this.fase == 1">
            <div class="col-xs-10 col-sm-10">
                <h3>{{ $t('InfoAppAmbasciata') }}</h3>
                <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                        <label class="control-label">{{ $t('DataAppAmbasciata') }}</label>
                        <input type="date" class="form-control" :min="oggi" v-model="visto.Data_Appuntamento_Ambasciata__c" :disabled="appAmbasciataSaved"/>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{ $t('NomeAmbasciata') }}</label>
                        <input type="text" class="form-control" @input="visto.Nome_Ambasciata__c = visto.Nome_Ambasciata__c.toUpperCase()" v-model="visto.Nome_Ambasciata__c" :disabled="appAmbasciataSaved"/>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{ $t('IndirizzoAmbasciata') }}</label>
                        <input type="text" class="form-control" @input="visto.Indirizzo_Ambasciata__c = visto.Indirizzo_Ambasciata__c.toUpperCase()" v-model="visto.Indirizzo_Ambasciata__c" :disabled="appAmbasciataSaved"/>
                    </div>

                    <div class="text-right">
                        <button v-if="!appAmbasciataSaved" type="submit" class="btn btn-primary" :disabled="saveAppDisabled" @click.prevent="saveModifiche(fase)">
                            {{ $t('Salva') }}
                        </button>
                        <button v-else-if="appAmbasciataSaved && !modAppDisabled" type="submit" class="btn btn-primary" @click.prevent="appAmbasciataSaved = false">
                            {{ $t('Modifica') }}
                        </button>
                    </div>
                </div>
            </div> 
        </div>

        <div v-if="this.fase == 2">
            <div class="text-center mb-2">
                <div class="btn-group btn-group-sm">
                    <button type="button" class="btn" :class="vistoConcesso? 'btn-primary': 'btn-outline-secondary'" @click="vistoConcesso = true" :disabled="infoVistoSaved">Concesso</button>
                    <button type="button" class="btn" :class="!vistoConcesso? 'btn-primary': 'btn-outline-secondary'" @click="vistoConcesso = false" :disabled="infoVistoSaved">Non concesso</button>
                </div>
            </div>

            <div class="col-xs-10 col-sm-10">
                <h3>{{ $t('InfoVisto') }}</h3>
                <div class="col-sm-12 col-md-6">

                    <div v-if="vistoConcesso">
                        <div class="form-group">
                            <label class="control-label">{{ $t('DataDecorrenzaVisto') }}</label>
                            <input type="date" class="form-control" :min="oggi" v-model="visto.Data_Decorrenza_Visto__c" :disabled="infoVistoSaved"/>
                        </div>
                        <div class="form-group">
                            <label class="control-label">{{ $t('DataScadenzaVisto') }}</label>
                            <input type="date" class="form-control" :min="oggi" v-model="visto.Scadenza_Visto__c" :disabled="infoVistoSaved"/>
                        </div>

                        <div class="text-right">
                            <button v-if="!infoVistoSaved" type="submit" class="btn btn-primary" :disabled="saveInfoVistoDisabled" @click.prevent="conferma()">
                                {{ $t('Salva') }}
                            </button>
                        </div>
                    </div>

                    <div v-else>
                        <div class="form-group">
                            <label class="control-label">{{ $t('MotivoRifiuto') }}</label>
                            <input type="text" class="form-control" @input="visto.Motivo_Rifiuto__c = visto.Motivo_Rifiuto__c.toUpperCase()" v-model="visto.Motivo_Rifiuto__c" :disabled="infoVistoSaved"/>
                        </div>

                        <div class="text-right">
                            <button v-if="!infoVistoSaved" type="submit" class="btn btn-primary" :disabled="saveInfoVistoDisabled" @click.prevent="conferma()">
                                {{ $t('Salva') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div> 

        </div>

        <div v-if="this.fase == 3">
            <div class="col-xs-10 col-sm-10">
                <h3>{{ $t('InfoVolo') }}</h3>
                <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                        <label class="control-label">{{ $t('DataOraVolo') }}</label>
                        <input v-if="!datiVoloSaved" type="datetime-local" :min="oggiTime" class="form-control" v-model="dataOraArrivoString"/>
                        <input v-else type="text" class="form-control" v-model="dataOraArrivoString" :disabled="datiVoloSaved"/>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{ $t('AeroportoArrivo') }}</label>
                        <input type="text" class="form-control" @input="visto.Aeroporto_Arrivo_Volo__c = visto.Aeroporto_Arrivo_Volo__c.toUpperCase()" v-model="visto.Aeroporto_Arrivo_Volo__c" :disabled="datiVoloSaved"/>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{ $t('NumBiglietto') }}</label>
                        <input type="text" class="form-control" @input="visto.Flight_ticket__c = visto.Flight_ticket__c.toUpperCase()" v-model="visto.Flight_ticket__c" :disabled="datiVoloSaved"/>
                    </div>

                    <div class="text-right">
                        <button v-if="!datiVoloSaved" type="submit" class="btn btn-primary" :disabled="saveDatiVoloDisabled" @click.prevent="saveModifiche(fase)">
                            {{ $t('Salva') }}
                        </button>
                        <button v-else-if="datiVoloSaved && !modDatiVoloDisabled" type="submit" class="btn btn-primary" @click.prevent="datiVoloSaved = false">
                            {{ $t('Modifica') }}
                        </button>
                    </div>
                </div>
            </div> 
        </div>

        <div v-if="this.fase == 4">
            <div class="col-xs-10 col-sm-10">
                <h3>{{ $t('InfoAppAlma') }}</h3>
                <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                        <label class="control-label">{{ $t('DataAppAlma') }}</label>
                        <input type="text" class="form-control" :placeholder="$t('AttesaData')" v-model="visto.Data_Appuntamento_in_Alma_Permesso__c" disabled/>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{ $t('DataAppPostaKit') }}</label>
                        <input type="text" class="form-control" :placeholder="$t('AttesaData')" v-model="visto.Data_Appuntamento_Posta_x_Kit__c" disabled/>
                    </div>
                    <div class="form-group">
                        <label class="control-label">{{ $t('DataFotoSegnalazione') }}</label>
                        <input type="text" class="form-control" :placeholder="$t('AttesaData')" v-model="visto.Data_Foto_Segnalazione__c" disabled/>
                    </div>
                </div>
            </div> 
        </div>

    </div>
</template>
  
<script>
export default {
    name: 'visto',
    components: {},
    props: {},
    data: function () {
        return {
            fase: 0,
            fasi: [
                {fase: 1, title: 'AttesaAppAmbasciata', class: ''},
                {fase: 2, title: 'ConcessioneVisto', class: ''},
                {fase: 3, title: 'InfoVolo', class: ''},
            ],
            appAmbasciataSaved: false,
            modAppDisabled: false,
            vistoConcesso: true,
            infoVistoSaved: false,
            datiVoloSaved: false,
            modDatiVoloDisabled: false,
            dataOraArrivoString: '',
            oggi: '',
            oggiTime: '',
        }
    },
    computed: {
        visto() {
            if(this.$store.state.visto != {}) return this.$store.state.visto
            return {}
        },
        saveAppDisabled() {
            if(this.visto.Data_Appuntamento_Ambasciata__c == null || this.visto.Nome_Ambasciata__c == null || this.visto.Indirizzo_Ambasciata__c == null) return true
            return false
        },
        saveInfoVistoDisabled() {
            if(this.vistoConcesso && (this.visto.Data_Decorrenza_Visto__c == null || this.visto.Scadenza_Visto__c == null)) return true
            if(!this.vistoConcesso && this.visto.Motivo_Rifiuto__c == null) return true
            return false
        },
        saveDatiVoloDisabled() {
            if(this.dataOraArrivoString == null || this.visto.Aeroporto_Arrivo_Volo__c == null || this.visto.Flight_ticket__c == null) return true
            return false
        }
    },
    mounted: function () {
        this.setOggi()
        this.setFasi()
    },
    methods: {
        setFasi() {
            if(this.visto.RecordType.Name == 'Visto D') this.fasi.push({fase: 4, title: 'InfoAppAlma', class: ''})

            this.fase = this.faseCorrente()

            this.activeFase()

            if(this.fase > 1) this.appAmbasciataSaved = true
            if(this.fase == 2 && this.visto.Motivo_Rifiuto__c != null) {
                this.infoVistoSaved = true
                this.vistoConcesso = false
            }
            if(this.fase > 2) this.infoVistoSaved = true
            if(this.fase > 3 || (this.fase == 3 && this.visto.RecordType.Name == 'Visto C')) {
                this.datiVoloSaved = true
                this.dataOraArrivoString = this.visto.Data_e_Ora_Arrivo_Volo__c.split('T')[0] + ' ' + this.visto.Data_e_Ora_Arrivo_Volo__c.split('T')[1].split(':00.')[0]
            }

            this.modAppDisabled = new Date(this.visto.Data_Appuntamento_Ambasciata__c) < new Date() ? true : false
            this.modDatiVoloDisabled = new Date(this.visto.Data_e_Ora_Arrivo_Volo__c) < new Date() ? true : false
        },
        faseCorrente() {
            if(this.visto.Data_Appuntamento_Ambasciata__c == null || this.visto.Nome_Ambasciata__c == null 
            || this.visto.Indirizzo_Ambasciata__c == null) { return 1 }
            else if(this.visto.Data_Decorrenza_Visto__c == null || this.visto.Scadenza_Visto__c == null) { return 2 }
            else if(this.visto.Data_e_Ora_Arrivo_Volo__c == null || this.visto.Aeroporto_Arrivo_Volo__c == null || this.visto.Flight_ticket__c == null
                || this.visto.RecordType.Name == 'Visto C' ) { return 3 } 
            else if( this.visto.RecordType.Name == 'Visto D' ) { return 4 }
            return 3
        },
        setOggi() {
            var oggi = new Date()
            var month = '00' + (oggi.getMonth()+1)
            var day = '00' + oggi.getDate()
            this.oggi = oggi.getFullYear() + '-' + month.slice(month.length-2, month.length) + '-' + day.slice(day.length-2, day.length)
            console.log('oggi',this.oggi)
            this.oggiTime = this.oggi + 'T00:00'
        },
        cambiaFase(f) {
            var faseCorrente = this.faseCorrente()
            if(f.fase <= faseCorrente) {
                this.fase = f.fase
                this.activeFase()
            }
        },
        activeFase() {
            for(var f of this.fasi) {
                f.class = ''
                if(this.fase == f.fase) f.class = 'active'
            }
        },
        conferma() {
            if(confirm('Una volta confermato non sarà più possibile modificare. Confermi?')) {
                this.saveModifiche(this.fase)
            }
        },
        saveModifiche(fase) {
            if(fase == 1) this.visto.Stato__c = 'Attesa visto ambasciata'
            
            if(this.dataOraArrivoString != '') this.visto.Data_e_Ora_Arrivo_Volo__c = new Date(this.dataOraArrivoString)
            this.$store.dispatch('updateVisto', {visto: this.$store.state.visto} )
            .then(() => {
                if(fase == 1) this.appAmbasciataSaved = true
                if(fase == 2) this.infoVistoSaved = true
                if(fase == 3) this.datiVoloSaved = true
            })
        },

    }
}
</script>

<style>
.breadcrumb-item+.breadcrumb-item::before {
    content: '>'
}
.breadcrumb-item.active { 
    color: #d29500;
    text-decoration: underline;
}
</style>