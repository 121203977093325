<template>
    <div class="home">
      <navbar v-bind:menulist="$store.state.mainmenu" ></navbar>
      <herobig v-bind:title="$t('title')"></herobig>
  
      <div v-if="!esamiIntermedi && !esamiFinali">
      <h2 style="text-align=center" class="welcome">{{$t('Esami')}}</h2>
  
      <div class="row">
        <div class="mx-auto col-lg-3 col-xs-12 col-sm-12"></div>
        <div class="text-center col-md-3 col-lg-3 col-xs-12 col-sm-12" style="padding:10px;">
          <button class="btn btn-primary" :disabled="disableIntermedio" v-on:click="selectEsami('esamiIntermedi')">{{$t('EsamiIntermedi')}}</button>
        </div>
  
        <div class="text-center col-md-3 col-lg-3 col-xs-12 col-sm-12" style="padding:10px;">
          <button class="btn btn-primary" :disabled="!disableIntermedio" v-on:click="selectEsami('esamiFinali')">{{$t('EsamiFinali')}}</button>
        </div>
        <div class="mx-auto col-lg-3 col-xs-12 col-sm-12"></div>
      </div>
      </div>

      <tesi v-if="esamiIntermedi" :titolo="$t('EsameIntermedio')" :tipo="'Esame Intermedio'" :uploadDossier="false"></tesi>

      <tesi v-if="esamiFinali" :titolo="$t('Tesi')" :tipo="'Esame Finale'" :uploadDossier="true"></tesi>
  
      <footerline></footerline>
  
    </div>
  </template>
  
  <script>
    // @ is an alias to /src
    import navbar from '@/components/navbar.vue'
    import herobig from '@/components/herobig.vue'
    import footerline from '@/components/footerline.vue'
    import tesi from '@/components/tesi.vue'
  
    export default {
      name: 'Home',
      components: {
        navbar,
        herobig,
        tesi,
        footerline
      },
      data: function () {
        return {
          esame: '',
          esamiIntermedi: false,
          esamiFinali: false
        };
      },
      computed: {
        tesi: function() {
          if(this.esame == 'esamiIntermedi') return true;
          if(this.esame == 'esamiFinali') return true
          return false;
        },
        disableIntermedio: function() {
          if(this.$store.state.idOpptyTesi.IsActiveTesi__c) return true
          return false;
        }
      },
      methods: {
        selectEsami(e){
          this.esame = e
          console.log('esame',e)
          if(e == 'esamiIntermedi') this.esamiIntermedi = true
          if(e == 'esamiFinali') this.esamiFinali = true
        }
      }
    }
  </script>