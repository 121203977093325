<template>
  <!-- Nav menu -->
  <nav class="navbar sticky-top navbar-expand-lg blu-alma navbar-dark">
    <!-- Brand -->
    <a v-if="$i18n.locale == 'it'" class="navbar-brand" href="#"><img src="img/logoitbianco.png" class="logo" alt="Alma Logo" data-retina="true"></a>
    <a v-if="$i18n.locale == 'en'" class="navbar-brand" href="#"><img src="img/logoenbianco.png" class="logo" alt="Alma Logo" data-retina="true"></a>

    <!-- Toggler/collapse Button -->
    <div> 
      <button v-if="notifiche > 0" @click="setShow" type="button" class="btn d-lg-none d-xl-none d-xxl-none alerts-button-m">
        <i class="bi bi-bell-fill alerts-icon-m"></i>
        <span class="badge badge-pill badge-warning alerts-badge-m">{{ notifiche }}</span>
      </button>
        
      <button @click="show = !show" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    
    <!-- Navbar links -->
    <div :class="{ 'collapse.show': show, 'collapse': !show }" class="navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav">
        <li class="nav-item" v-for="item in menulist" :key="item.title">
          <a class="nav-link" href="" @click.prevent="goto(item)">{{ item.title }}</a>
        </li>

        <li class="nav-item" v-if="$store.getters.logged">
          <a class="nav-link" href="" @click.prevent="goto({ path: 'Interventi', params: { from: from } })">{{ this.$i18n.locale == 'it' ? 'ASSISTENZA' : 'SUPPORT' }}</a>
        </li>

        <li class="nav-item" v-if="$store.getters.isEcommerceVisible">
          <a class="nav-link" href="" @click.prevent="goto({ title: 'SHOP', path: 'EcommerceHome' })">SHOP</a>
        </li>

        <li class="nav-item" v-if="$store.getters.logged">
          <!-- User Icon -->
          <a class="nav-link" href="" @click.prevent="gotoProfilo()">
            <i class="bi bi-person-circle"></i>
            <span class="px-1">
              {{ ($store.state.userprofile.FirstName ? $store.state.userprofile.FirstName : '') + ' ' + ($store.state.userprofile.LastName ? $store.state.userprofile.LastName : '') }}
            </span>
          </a>
        </li>

        <li class="nav-item" v-if="$store.getters.logged && $store.state.opptyCorso.IsActivePresenze__c">
          <a class="nav-link" href="/#/presenze">
            <span class="px-1">{{ this.$i18n.locale == 'it' ? 'PRESENZA' : 'TRAINEESHIP' }}</span>
          </a>
        </li>

        <!-- accesso facile apri porte -->
        <li class="nav-item" v-if="$store.getters.logged && ($store.state.opptyCorso.IsActive__c || $store.state.opptyCorso.IsActiveAperturaPorte__c)">
          <a class="nav-link" href="/#/utility">
            <span class="px-1">UTILITY</span>
          </a>
        </li>

        <li class="nav-item" v-if="!$store.getters.logged">
          <a class="nav-link" href="" @click.prevent="gotoProfilo()">
            {{ $t('registrati') }}
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link" href="" @click.prevent="cambia">{{ $t('cambialingua') }}</a>
        </li>
      </ul>
       
    </div>
     
    <button v-if="notifiche > 0" @click="setShow" type="button" class="btn d-none d-lg-block alerts-button-d">
      <i class="bi bi-bell-fill alerts-icon-d"></i>
      <span class="badge badge-pill badge-warning alerts-badge-d">{{ notifiche }}</span>
    </button>

    <modalnotify :show="showmodal" :notifichetxt="notstxt" @cancel="setShow"></modalnotify>
  </nav>
</template>

<script>
  import modalnotify from './modalnotify.vue'
  
  export default {
    name: 'navbar',
    components: {
      modalnotify
    },
    props: {
      menulist: Array,
      from:     String
    },
    mounted: function() {
      this.getNotifiche()

      if (this.from) {
        this.menulist.forEach(item => {
          if (item.path == 'Interventi')
            item.params = { from: this.from }
        })
      }
    },
    data: function() {
      return {
        show:      false,
        lingua:    'it', // fallback
        showmodal: false,
        notifiche: 0,
        notstxt:   []
      }
    },
    methods: {
      setShow: function() {
        this.showmodal = !this.showmodal
      },
      cambia: function() {
        console.log('cambia lingua: ' + this.$i18n.locale)
        console.log(this.$i18n)

        if (!this.$i18n.locale) {
          if (this.lingua == 'en') {
            this.$i18n.locale = 'it'
            this.setMenu('it')
          } else {
            this.$i18n.locale = 'en'
            this.setMenu('en')
          }
          return
        }

        console.log('cambia lingua standard')

        if (this.$i18n.locale == 'en') {
          this.$i18n.locale = 'it'
          this.setMenu('it')
          return
        }

        if (this.$i18n.locale == 'it') {
          this.$i18n.locale = 'en'
          this.setMenu('en')
          return
        }


      },
      setMenu: function(lang) {
        this.lingua = lang
        this.$store.commit('SET_LINGUA', this.lingua)
        console.log('LINGUA',this.$store.state.lingua)

        var mainmenu = []
        
        if (lang == 'it') {
          mainmenu = [
            { title: 'HOME',             path: 'Home'       },
            { title: 'INFO GENERALI',    path: 'Info'       },
            { title: 'AREA CANDIDATURA', path: 'Candidati'  },
            { title: 'MY ALMA LIFE',     path: 'MyAlmaLife' },
            //{ title: 'ASSISTENZA',       path: 'Interventi', params: { from: this.from }} // path: 'Assistenza'
          ]

          this.$store.commit('SET_MAINMENU', mainmenu)
          console.log('$store.state.mainmenu',this.$store.state.mainmenu)
          return
        }

        if (lang == 'en') {
          mainmenu = [
            { title: 'HOME',           path: 'Home'       },
            { title: 'GENERAL INFO',   path: 'Info'       },
            { title: 'CANDIDACY AREA', path: 'Candidati'  },
            { title: 'MY ALMA LIFE',   path: 'MyAlmaLife' },
            //{ title: 'ASSISTANCE',     path: 'Interventi', params: { from: this.from }} // path: 'Assistenza'
          ]

          this.$store.commit('SET_MAINMENU', mainmenu)
          console.log('$store.state.mainmenu',this.$store.state.mainmenu)
          return
        }
      },
      gotoProfilo: function() {
        this.show = false
        this.$router.push({ name: 'Profilo' })
      },
      goto: function(item) {
        if (this.$store.state.routerlock) {
          if (!confirm('Si desidera uscire?')) {
            this.show = false
            return
          }

          this.$store.commit('SET_ROUTERLOCK', false)
        }

        // console.log('goto:')
        // console.log(item)

        this.$emit('goto')

        this.show = false
        this.$router.push({ name: item.path, params: item.params })
      },
      getNotifiche: function() {
        if (this.$store.state.userprofile.Opportunit_con_richiesta_dati__c > 0 && this.$store.state.opptyCorso.Fatturante_compilato__c === false) {
          this.notifiche += 1
          this.notstxt.push({ txt: 'Compilare o Confermare dati fatturante', istruzioni:'Fatturazione > Fatturazione Corsi', link:'Profilo' })
        }

        if (this.$store.state.opptyCorso.Visibilit_abbigliamento__c && this.$store.state.opptyCorso.Abbigliamento_Compilato__c === false) {
          this.notifiche += 1
          this.notstxt.push({ txt: 'Compilare o Confermare dati abbigliamento', istruzioni:'> Abbigliamento', link:'Candidato' })
        }

        if (this.$store.state.opptyHousingFatt.Housing_compilazione_dati__c && this.$store.state.opptyHousingFatt.Fatturante_Housing_compilato__c === false) {
          this.notifiche += 1
          this.notstxt.push({ txt: 'Compilare o Confermare dati fatturante housing', istruzioni:'Fatturazione > Fatturazione Housing', link:'Profilo' })
        }

        if (this.$store.state.opptyCorso.Documenti_richiesti__c === true) {
          this.notifiche += 1
          this.notstxt.push({ txt: 'Carica Documenti', istruzioni:'> Documenti', link:'Candidato' })
        }

        if(this.$store.state.righeOrdineEcommerce.length > 0) {
          for(var ro of this.$store.state.righeOrdineEcommerce) {
            console.log('ro',ro)
            this.notifiche += 1
            this.notstxt.push({ 
              txt: 'Ordine N.'+ ro.Name +' pronto al ritiro', 
              istruzioni:'Il tuo '+ro.Descrizione__c+' è pronto', 
              link:'EcommerceStorico' 
            })
          }
        }
      }
    }
  }
</script>

<style scoped>
  /* Alerts button */
  /* Mobile */
  .alerts-button-m {
    background-color: transparent !important;
    color: white !important;
    vertical-align: top !important;
  }
  .alerts-badge-m {
    font-size: .4rem !important;
    vertical-align: top !important;
  }

  .alerts-icon-m {
    font-size: 16px;
  }

  /* Desktop */
  .alerts-button-d {
    background-color: transparent !important;
    color: white !important;
    vertical-align: text-top !important;
  }
  .alerts-badge-d {
    font-size: .5rem !important;
    vertical-align: top !important;
  }

  .alerts-icon-d {
    font-size: 16px;
  }
</style>